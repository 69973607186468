import { format } from "rut.js";
import "../../../../stylesheets/certificate";
import { formatPhoneNumber, loadPhoneNumber } from "../../../backoffice/utils";
import { Fn, basicValidation } from "../../../utils";
import { donandoFooterColor } from "../../utils";

$(document).ready(function () {
  basicValidation();
  loadPhoneNumber();
  donandoFooterColor();

  $(".iti__country-list li").click(function () {
    this?.parentElement?.parentElement?.nextSibling?.setAttribute(
      "data-code",
      $(this).data("dial-code")
    );
    formatPhoneNumber();
  });

  $("#phone_number_hidden_input").change(function (e) {
    formatPhoneNumber();
  });
  const updateRequiredFields = (radio_checked) => {
    if (radio_checked) {
      $("#tax_id_form").show();
      $("#donator_tax_id").prop("required", true);
      $("#donator_email").prop("required", true);
      if ($("#donator_email_fundraising")) {
        $("#donator_email_fundraising").prop("required", true);
        $("#donator_email_label").html("Ingresa tu mail");
        $("#donator_email_fundraising").addClass("form-control", true);
      }
    } else {
      $("#donator_tax_id").prop(
        "required",
        $("#tax_id_form").attr("data-optional")
      );
      if (
        $("#donator_email_fundraising").length > 0 &&
        $('[name="recurring_radio"]#single-donation')[0].checked
      ) {
        $("#donator_email_fundraising").prop("required", false);
        $("#donator_email_label").html(
          "Ingresa tu mail <span id='optional'>(Opcional)</span>"
        );
        $("#donator_email_fundraising").removeClass("form-control", true);
      }
    }
  };
  $('[name="donator[tax_benefit]"]').change(function () {
    if (this.value == "true") {
      updateRequiredFields(true);
    } else {
      updateRequiredFields(false);
    }
  });

  $("#donator_tax_id").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  $("#donator_tax_id").on("blur", ({ target }) => {
    const rutField = $("#donator_tax_id")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    if (rutValidity === false) {
      $("#donator_tax_id").addClass("is-invalid");
      $("#donator_tax_id").removeClass("is-valid");
      $("#donator_certificates").removeClass("was-validated");
    } else {
      $("#donator_tax_id").addClass("is-valid");
      $("#donator_tax_id").removeClass("is-invalid");
      $("#donator_certificates").addClass("was-validated");
    }
  });
});
